<template>
  <div>
    <portal to="breadcrumb-right">
      <b-button
        v-if="isHost && $can('Core.Files.Manage')"
        class="mr-1"
        size="sm"
        variant="secondary"
        @click="openCreateFileModal"
      >
        New file...
      </b-button>
    </portal>

    <b-overlay :show="isLoading">
      <b-card>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  File
                </th>
                <th>Size</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="file in files"
                :key="file.id"
              >
                <td>
                  {{ file.displayName }}
                  <template v-if="file.shortDescription">
                    <br>
                    <small class="text-muted">{{ file.shortDescription }}</small>
                  </template>
                </td>
                <td>
                  {{ file.size | prettyBytes }}
                </td>
                <td>
                  <b-button
                    variant="primary"
                    size="sm"
                    :href="file.downloadLink"
                    target="_blank"
                  >
                    <feather-icon icon="DownloadIcon" />
                    Download
                  </b-button>
                  <b-button
                    v-if="isHost && $can('Core.Files.Manage')"
                    size="sm"
                    variant="secondary"
                    class="ml-1"
                    @click="openManageModal(file.id)"
                  >
                    <feather-icon icon="SettingsIcon" />
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </b-overlay>

    <ManageModal
      ref="manageModal"
      @updated="loadData"
    />

    <CreateFileModal
      ref="createFileModal"
      @created="loadData"
    />

  </div>
</template>

<script>

import {
  BCard, BButton, BOverlay,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import FileManagerService from '@/service/fileManager.service'
import ManageModal from './ManageModal.vue'
import CreateFileModal from './CreateFileModal.vue'

export default {
  components: {
    BCard, BButton, BOverlay, ManageModal, CreateFileModal,
  },
  data() {
    return {
      isLoading: false,
      files: [],
    }
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      FileManagerService.getAllListAsync()
        .then(result => {
          this.files = result.items
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    openManageModal(fileId) {
      this.$refs.manageModal.show(fileId)
    },
    openCreateFileModal() {
      this.$refs.createFileModal.show()
    },
  },
}

</script>
