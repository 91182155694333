/* eslint-disable indent */
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig, parseGetParams } from './serviceManager'

export default {

    async getAsync(id, params, config) {
        const requestConfig = buildAxiosRequestConfig(config)
        requestConfig.params = params
        requestConfig.paramsSerializer = parseGetParams

        const result = await axios.get(`/core/file/${id}`, requestConfig)
        return result.data
    },

    async getAllListAsync(params, config) {
        const requestConfig = buildAxiosRequestConfig(config)
        requestConfig.params = params
        requestConfig.paramsSerializer = parseGetParams

        const result = await axios.get('/core/file', requestConfig)
        return result.data
    },

    async createAsync(data, config) {
        const response = await axios.post('/core/file', data, buildAxiosRequestConfig(config))
        return response
    },

    async getFileUploadLinkAsync(fileName, config) {
        const result = await axios.get(`/core/file/uploadUrl?fileName=${fileName}`, buildAxiosRequestConfig(config))
        return result.data
    },

    async updateAsync(id, data, config) {
        const response = await axios.put(`/core/file/${id}`, data, buildAxiosRequestConfig(config))
        return response
    },

    async deleteAsync(id, config) {
        const response = await axios.delete(`/core/file/${id}`, buildAxiosRequestConfig(config))
        return response
    },
}
