<template>
  <div>
    <b-modal
      v-model="isShow"
      title="Manage File"
      no-close-on-backdrop
      @ok="save"
    >
      <b-overlay :show="isLoading || isSaving">
        <b-form>
          <b-form-group label="Display name">
            <b-form-input
              v-model="file.displayName"
            />
          </b-form-group>
          <b-form-group label="Short description">
            <b-form-input
              v-model="file.shortDescription"
            />
          </b-form-group>
        </b-form>
      </b-overlay>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="danger"
          @click="deleteFile"
        >
          <feather-icon icon="DeleteIcon" />
          Delete
        </b-button>

        <b-button
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          OK
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

import {
  BModal, BOverlay, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import FileManagerService from '@/service/fileManager.service'

export default {
  components: {
    BModal,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
  },
  data() {
    return {
      isShow: false,
      isSaving: false,
      isLoading: false,
      file: {},
    }
  },
  methods: {
    show(fileId) {
      this.isShow = true
      this.isLoading = true

      FileManagerService.getAsync(fileId)
        .then(result => {
          this.file = result
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    save() {
      FileManagerService.updateAsync(this.file.id, {
        displayName: this.file.displayName,
        shortDescription: this.file.shortDescription,
      })
        .then(result => {
          this.$toast('File updated successfully.')
          this.$emit('updated', {
            file: result,
          })
          this.isShow = false
        })
    },
    deleteFile() {
      const self = this
      this.$swal({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          FileManagerService.deleteAsync(self.file.id)
            .then(() => {
              self.$toast('File has been deleted.')
              this.$emit('updated', {
                file: result,
              })
              this.isShow = false
            })
        }
      })
    },
  },
}

</script>
