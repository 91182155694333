<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <b-form @submit.prevent="save()">
        <b-modal
          v-model="isShow"
          title="Manage File"
          no-close-on-backdrop
          static
          @ok="save"
        >
          <b-overlay :show="isSaving">
            <validation-provider
              v-slot="validationContext"
              name="Display name"
              :rules="{ required: true, max: 50 }"
            >
              <b-form-group
                label="Display name"
                label-for="displayName"
              >
                <b-form-input
                  v-model="fileData.displayName"
                  name="displayName"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback id="input-displayName-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="validationContext"
              name="Short description"
              :rules="{ max: 120 }"
            >
              <b-form-group
                label="Short description"
                label-for="shortDescription"
              >
                <b-form-input
                  v-model="fileData.shortDescription"
                  name="shortDescription"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback id="input-shortDescription-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group
              label="File"
            >
              <b-form-file
                v-model="file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />

              <b-progress
                v-if="file"
                v-model="uploadProgress"
                :max="file.size"
              />
            </b-form-group>
          </b-overlay>
          <template #modal-footer="{ cancel }">
            <b-button
              @click="cancel()"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :disabled="!fileUploaded || !file || invalid"
              type="submit"
            >
              OK
            </b-button>
          </template>
        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>

import {
  BModal, BOverlay, BFormGroup, BFormInput, BFormFile, BProgress, BButton, BFormInvalidFeedback, BForm,
} from 'bootstrap-vue'
import {
  // eslint-disable-next-line no-unused-vars
  required, max, mapServerFieldValidationErrors,
} from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import FileManagerService from '@/service/fileManager.service'

const { BlockBlobClient } = require('@azure/storage-blob')

export default {
  components: {
    BModal,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormFile,
    BProgress,
    BButton,
    BFormInvalidFeedback,
    BForm,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      isShow: false,
      isSaving: false,
      fileData: {},
      file: null,
      uploadProgress: 0,
      blobId: null,
      fileUploaded: false,
    }
  },
  watch: {
    file(val) {
      if (val) {
        this.uploadFile(val)
      }
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    show() {
      this.fileData = {}
      this.file = null
      this.uploadProgress = 0
      this.isShow = true
      this.fileUploaded = false
    },
    uploadFile(file) {
      this.uploadProgress = 0
      this.fileUploaded = false
      FileManagerService.getFileUploadLinkAsync(file.name)
        .then(result => {
          // eslint-disable-next-line no-unused-vars
          const { uploadUrl, blobId } = result
          this.blobId = blobId
          const self = this

          const blockBlobClient = new BlockBlobClient(uploadUrl)
          blockBlobClient.uploadData(file, {
            onProgress(event) {
              self.uploadProgress = event.loadedBytes
            },
            blobHTTPHeaders: {
              blobContentType: file.type,
            },
          })
            .then(() => {
              this.fileUploaded = true
            })
        },
        err => {
          this.$swal({
            title: 'Something went wrong!',
            text: err.response.data.error.message,
            icon: 'error',
          })
          this.file = null
          this.fileUploaded = false
        })
    },
    save() {
      const data = {
        displayName: this.fileData.displayName,
        shortDescription: this.fileData.shortDescription,
        blobId: this.blobId,
      }

      FileManagerService.createAsync(data)
        .then(result => {
          this.$toast('File created')
          this.$emit('created', {
            file: result,
          })
          this.isShow = false
        },
        err => {
          this.$swal({
            title: 'Something went wrong!',
            text: err.response.data.error.message,
            icon: 'error',
          })
        })
    },
  },
}

</script>
